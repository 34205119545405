<template>
  <div class="vg_wrapper" v-loading="isLoading">
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="cpttForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('cpttForm')"
      />
      <el-button
        type="primary"
        size="small"
        class="vd_export"
        @click="buttonRefresh()"
        v-if="isShow"
        >刷新</el-button
      >
      <el-button @click="drawer = true" v-if="specialDataFlag && isShow && cpttForm.status === 2" size="small" type="warning" class="vd_export1">分享账套</el-button>
    </div>
    <el-form
      ref="cpttForm"
      :model="cpttForm"
      :rules="rules"
      :disabled="isShow"
      label-width="120px"
      size="mini"
    >
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_no">
            <el-input
              v-model="cpttForm.cptt_no"
              maxlength="10"
              @input="cpttForm.cptt_no = helper.keepEngNum2(cpttForm.cptt_no)"
              show-word-limit
              placeholder="请填写公司编号（仅支持数字和英文）"
            >
              <!-- <template slot="prepend">{{pref}}</template> -->
            </el-input>
          </el-form-item>
          <el-form-item label="公司简称" prop="cptt_abbr">
            <el-input
              v-model="cpttForm.cptt_abbr"
              maxlength="10"
              show-word-limit
              placeholder="请填写公司简称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_name">
            <el-input
              v-model="cpttForm.cptt_name"
              maxlength="50"
              show-word-limit
              placeholder="请填写公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址">
            <el-input
              type="textarea"
              v-model="cpttForm.cptt_address"
              placeholder="请填写公司地址"
              maxlength="100"
              show-word-limit
              :autosize="{ minRows: 2, maxRows: 6 }"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司类型" prop="cptt_type">
            <el-select
              v-model="cpttForm.cptt_type"
              placeholder="请选择公司类型"
              clearable
            >
              <el-option
                v-for="item in cpttTypeGroupOpt"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司电话" prop="cptt_phone">
            <el-input
              type="textarea"
              v-model="cpttForm.cptt_phone"
              maxlength="100"
              show-word-limit
              placeholder="请填写公司电话"
              :autosize="{ minRows: 2, maxRows: 6 }"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-divider content-position="center" class="vd_div" v-if="isAcct"
        >凡爱专属管理</el-divider
      > -->
      <el-divider></el-divider>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser
            :isCookie="false"
            :stffForm="stffForm"
            v-if="isShowInputer"
          ></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-drawer :visible.sync="drawer" :direction="direction" :before-close="handleCloseDrawer">
      <div class="vd_draw">
        <el-card>
          <el-button type="success" class="vg_mb_16" size="small" v-if="!acctPreseFlag" @click="acctPreseFlag = true">编辑</el-button>
          <el-button type="primary" class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="preseType()">保存</el-button>
          <el-button class="vg_mb_16" size="small" v-if="acctPreseFlag"  @click="cancelType()">取消</el-button>
          <el-button type="primary" size="small" v-if="acctPreseFlag" plain @click="addType()">添加</el-button>
          <el-table :data="cpttForm.cptt_acct_list" border v-if="flag">
            <el-table-column label="账套编号">
              <template slot-scope="scope">
                <el-select
                 size="small"
                  @change="acctSelect(scope)"
                  v-model="cpttForm.cptt_acct_list[scope.$index].acct_id"
                  placeholder="请选择账套"
                  clearable
                  :disabled="
                    !acctPreseFlag
                  "
                >
                  <el-option
                    v-for="item in acctCopy"
                    :key="item.acct_id"
                    :label="item.acct_no"
                    :value="item.acct_id"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="账套名称">
              <template slot-scope="scope">
                <span>{{ scope.row | filterAcct(acctCopy) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否为本公司账套" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  @change="radioChange(scope)"
                  v-model="scope.row.checked"
                  :disabled="!acctPreseFlag"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  v-if="
                    cpttForm.cptt_acct_list[scope.$index].cptt_acct_share === 0
                  "
                  @click="delType(scope)"
                  :disabled="!acctPreseFlag"
                  class="vg_pointer"
                  >移除</el-link
                >
                <el-link type="primary" v-else :disabled="!acctPreseFlag">系统</el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { get } from "@api/request";
import { post } from "@api/request";
import { cpttAPI } from "@api/modules/comptitle";
import inputUser from "@/views/component/inputUser";
import editHeader from "@/views/component/editHeader";
import options from "@/views/component/common/options";
import { acctAPI } from "@api/modules/acct";
export default {
  name: "CpttEditMain",
  components: {
    inputUser,
    editHeader,
  },
  data() {
    return {
      cpttForm: {
        status: null,
        cptt_acct_id: "",
        cptt_no: "",
        cptt_name: "",
        cptt_type: null,
        cptt_address: "",
        cptt_phone: "",
        cptt_acct_list: [],
        cptt_abbr: "",
      },
      isAcct: false,
      stffForm: {},
      copyCpttList: [],
      key: 0,
      isLoading: true,
      isShowInputer: false,
      isShow: true,
      btn: {},
      cpttTypeGroupOpt: [],
      acctOpt: [],
      acctFctr: [],
      pref: "",
      flag: true,
      value: "",
      shareType: [
        {
          shareId: 0,
          shareName: "主动",
        },
        {
          shareId: 1,
          shareName: "被动",
        },
      ],
      rules: {
        cptt_no: [{ required: true, trigger: "blur" }],
        cptt_name: [{ required: true, trigger: "blur" }],
        cptt_type: [{ required: true, trigger: "change", message: " " }],
        cptt_abbr: [{ required: true, trigger: "change", message: " " }]
      },
      drawer: false,
      direction: 'rtl',
      acctCopy:[],
      cpttAcctCope:[],
      specialDataFlag:false,
      acctPreseFlag:false
    };
  },
  watch: {},
  created() {
    this.initData();
    this.cpttTypeGroupOpt = options.cpttOpts;
    if (this.$cookies.get("userInfo").acct_id === 1) {
      this.isAcct = true;
    }
  },
  methods: {
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit("isShow", this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm("是否撤销编辑?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.isShow = true;
          this.$emit("isShow", this.isShow);
          this.initData();
          this.$message({
            type: "info",
            message: "已撤销编辑!",
          });
        })
        .catch(() => {});
    },
    initData() {
      this.isLoading = true
      this.getCpttInfo();
      setTimeout(() => {
        this.getAccts();
        if(this.$cookies.get('userInfo').acct_id === 1){
          this.specialDataFlag = true
        }
      }, 50);
    },
    // 获取账套accts
    getAccts() {
      if(this.$cookies.get("userInfo").acct_id === 1){
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code === 0) {
            this.acctOpt = res.data.data;
            this.acctCopy = JSON.parse(JSON.stringify(this.acctOpt));
            for (let i = 0; i < this.acctCopy.length; i++) {
              if (this.acctCopy[i].acct_id === this.cpttForm.acct_id) {
                this.acctCopy[i].disabled = true;
              }
            }
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    }
   },
    // 获取账套accts
    getAcctFctr() {
      get(acctAPI.getAcctFctrs, { acct_id: this.cpttForm.cptt_acct_id })
        .then((res) => {
          if (res.data.code === 0) {
            this.acctFctr = res.data.data.list;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //提交表单
    submit(formName) {
      this.$confirm("确定提交？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log("error submit!");
              return false;
            }
          });
        })
        .catch((res) => {});
    },
    // 保存
    saveInfo() {
      let flag = true;
      flag = this.cpttForm.cptt_acct_list.every((item) => {
        return item.acct_id;
      });
      if (flag == false) {
        return this.$message.error("请填写账套名称");
      }
      this.cpttForm.cptt_id = this.$route.query.form_id;
      let cpttForm = {};
      cpttForm = Object.assign(cpttForm, this.cpttForm);
      post(cpttAPI.editCptt, cpttForm)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.isShow = true;
            this.copyCpttList = [];
            this.$emit("isShow", this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: "error",
              message: "公司编号重复", 
            });
            this.isShow = true;
            this.copyCpttList = [];
            this.$emit("isShow", this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
            this.isShow = true;
            this.copyCpttList = [];
            this.$emit("isShow", this.isShow);
            this.initData();
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 关闭特殊数据范围
    handleCloseDrawer(){
      let flag = false;
      for(let i=0;i<this.cpttForm.cptt_acct_list.length;i++){
        if(this.cpttForm.cptt_acct_list[i].acct_id === null){
          flag = true;
        }
      }
      if(flag){
        this.$message.warning('请把分享的账套补充完整')
      }else{
        if(this.acctPreseFlag){
          this.$message.warning('请先保存或取消分享账套的编辑')
        }else{
          this.drawer = false
        }
      }
    },
    // 保存分享账套
    preseType(){
      let temp = false
      for(let i=0;i<this.cpttForm.cptt_acct_list.length;i++){
        if(this.cpttForm.cptt_acct_list[i].acct_id === null){
          temp = true
        }
      }
      if(temp){
        this.$message.warning("请把账套名称补充完整")
      }else{
        this.$confirm("确认保存?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then((res)=>{
          let acctList = JSON.parse(JSON.stringify(this.cpttForm.cptt_acct_list))
          this.cpttAcctCope = this.cpttAcctCope.filter(item=>acctList.every(item1=>item.cptt_acct_id !== item1.cptt_acct_id))
          for(let i=0;i<this.cpttAcctCope.length;i++){
            this.cpttAcctCope[i].destroy_flag = 1
          }
          acctList = acctList.concat(this.cpttAcctCope)
          acctList.forEach((item) => {
            if (item.checked) {
              // item.cptt_acct_self = 1
              this.$set(item, "cptt_acct_self", 1);
            } else {
              this.$set(item, "cptt_acct_self", 0);
            }
          });
          if(acctList.length>0){
            post(cpttAPI.cpttShareAccts,{cptt_id:this.cpttForm.cptt_id,cptt_acct_list:acctList})
            .then(res=>{
              if(res.data.code === 0){
                this.$message.success("保存成功")
                // this.drawer = false
                this.acctPreseFlag = false
                this.initData()
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            })
            .catch(res=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            })
          }else{
            this.$message.success("保存成功")
            this.acctPreseFlag = false
            this.initData()
          }
        })
        .catch(()=>{})
      }
    },
    // 取消分享账套
    cancelType(){
      this.$confirm("确认取消?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then((res)=>{
        this.acctPreseFlag = false
        this.initData()
      })
      .catch(()=>{})
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id: null,
        cptt_acct_share: 0,
        cptt_acct_self: null,
        checked: false,
        destroy_flag: null,
      };
      this.cpttForm.cptt_acct_list.push(item);
    },
    // 特殊数据范围添加删除
    delType(scope) {
      if(scope.row.disabled){
        this.$message.error("该账套不能删除")
      }else{
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
      this.cpttForm.cptt_acct_list.splice(scope.$index, 1)
      })
      .catch(() => {});
      }
    },
    // 选择账套重复判断
    acctSelect(val) {
      if (val.row.acct_id !== null) {
        for (let i = 0; i < this.cpttForm.cptt_acct_list.length; i++) {
          if (i !== val.$index) {
            if (this.cpttForm.cptt_acct_list[i].acct_id === val.row.acct_id) {
              val.row.acct_id = null;
              this.$message({
                type: "warning",
                message: "账套选择重复",
              });
              return;
            }
          }
        }
      }
    },
    //获取表单信息
    getCpttInfo() {
      this.cpttForm.cptt_id = this.$route.query.form_id;
      get(cpttAPI.getCpttById, { cptt_id: this.cpttForm.cptt_id })
        .then((res) => {
          if (res.data.code === 0) {
            
            this.cpttForm = res.data.data.form;
            this.cpttForm.cptt_acct_list.forEach((item, index) => {
              this.$set(item, "checked", false);
              if (item.cptt_acct_self == 1) {
                this.$set(item, "cptt_acct_self", 1);
                this.$set(item, "checked", true);
              }
            });
            this.cpttAcctCope = JSON.parse(
              JSON.stringify(this.cpttForm.cptt_acct_list)
            );
            for (let i = 0; i < this.copyCpttList.length; i++) {
              this.copyCpttList[i].destroy_flag = 1;
            }
            this.getAcctFctr();
            if (this.cpttForm.cptt_acct_id === 0) {
              this.cpttForm.cptt_acct_id = "";
            }
            if (this.cpttForm.cptt_acct_fctr_id === 0) {
              this.cpttForm.cptt_acct_fctr_id = "";
            }
            // setTimeout(() => {
            //   let arr = this.acctOpt.filter(item=>item.acct_id === this.cpttForm.acct_id);
            //   this.pref = arr[0].acct_no.charAt(0) + '-';
            // }, 400);
            this.isShowInputer = true;
            this.stffForm.stff_name = this.cpttForm.stff_name;
            this.stffForm.dept_name = this.cpttForm.dept_name;
            this.stffForm.cptt_abbr = this.cpttForm.enter_cptt_abbr;
            this.stffForm.dept_team_name = this.cpttForm.dept_team_name;
            this.stffForm.user_id = this.cpttForm.user_id;
            this.stffForm.dept_id = this.cpttForm.dept_id;
            this.stffForm.stff_id = this.cpttForm.stff_id;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
          setTimeout(() => {
              this.isLoading = false;
            }, 500);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //单选 切换
    radioChange(scope) {
      let flag = scope.row.checked;

      if (flag) {
        this.cpttForm.cptt_acct_list.forEach((item, index) => {
          if (index !== scope.$index) {
            this.cpttForm.cptt_acct_list[index].checked = false;
          }
        });
      } else {
        this.cpttForm.cptt_acct_list.forEach((item, index) => {
          this.cpttForm.cptt_acct_list[index].checked = false;
        });
      }
    },
  },
  filters: {
    filterAcct(row, acctCopy) {
      let str = "";
      acctCopy.forEach((item) => {
        if (item.acct_id == row.acct_id) {
          str = item.acct_name;
        }
      });
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vg_wrapper ::v-deep.el-form-item {
  margin: 0;
  padding: 0;
}
.vg_wrapper ::v-deep .el-table td,
::v-deep.el-table th {
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}
::v-deep.is-disabled .el-input__inner {
  cursor: default;
  color: $color-conventional-text;
}
.vd_div {
  padding: 2px;
  ::v-deep.el-divider__text {
    color: #fff;
    background-color: #95a9d8;
  }
}
.vd_edhea {
  position: relative;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_export1{
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_rang {
  margin-top: 20px;
}
.vd_elform {
  display: flex;
}
.vd_mrf20 {
  margin-right: 20px;
}
.radio {
  margin-right: 0;
}
::v-deep .el-radio__label {
  visibility: hidden;
  margin: 0;
  padding: 0;
  // opacity: 0;
}
</style>